/* eslint-disable @typescript-eslint/no-shadow */
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import { useLazyGetTeamPlayersQuery } from 'manage-tritag/services/api/endpoints/teams'
import { Participant } from 'manage-tritag/services/api/endpoints/participants'
import {
  Match,
  useUpdateMatchMutation,
} from 'manage-tritag/services/api/endpoints/matches'
import {
  SecondGrid,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  TripleFormContent,
} from './styles'

const TryScorerForm = ({ matchData }: { matchData: Match }) => {
  const [teamPlayersTrigger, teamPlayersResult] = useLazyGetTeamPlayersQuery()
  const [players, setPlayers] = useState<Participant[]>([])
  const [updateMatch, { isLoading: updateLoading }] = useUpdateMatchMutation()
  const [selectedPlayer, setSelectedPlayer] = useState<Participant | undefined>(
    undefined,
  )
  const [teamType, setTeamType] = useState('')
  const [score, setScore] = useState('')
  const [homeTryScorers, setHomeTryScorers] = useState<Participant[]>([])
  const [awayTryScorers, setAwayTryScorers] = useState<Participant[]>([])
  const [homeTryScore, setHomeTryScore] = useState(0)
  const [awayTryScore, setAwayTryScore] = useState(0)

  const { data: teamPlayersData } = teamPlayersResult

  useEffect(() => {
    if (teamPlayersData) {
      setPlayers(teamPlayersData)
    }
  }, [teamPlayersData])

  useEffect(() => {
    if (matchData) {
      if (matchData.tryScorers.homeTeam.length) {
        setHomeTryScorers(matchData.tryScorers.homeTeam)
        let homeScore = 0
        for (const value of matchData.tryScorers.homeTeam) {
          if (value.event === 'try') {
            homeScore += 1
          } else if (value.event === '2-point try') {
            homeScore += 2
          }

          setHomeTryScore(homeScore)
        }
      }
      if (matchData.tryScorers.awayTeam.length) {
        setAwayTryScorers(matchData.tryScorers.awayTeam)
        let awayScore = 0
        for (const value of matchData.tryScorers.awayTeam) {
          if (value.event === 'try') {
            awayScore += 1
          } else if (value.event === '2-point try') {
            awayScore += 2
          }

          setAwayTryScore(awayScore)
        }
      }
    }
  }, [matchData])

  const handleTeamChange = (e: any) => {
    teamPlayersTrigger(e.target.value)
    if (matchData.homeTeam?._id === e.target.value) {
      setTeamType('homeTeam')
    }
    if (matchData.awayTeam?._id === e.target.value) {
      setTeamType('awayTeam')
    }
  }

  const handlePlayerChange = (e: any) => {
    const player = teamPlayersData?.filter(x => x._id === e.target.value)
    if (player) {
      setSelectedPlayer(player[0])
    }
  }

  const handleScoreChange = (e: any) => {
    setScore(e.target.value)
  }

  const parseExistingMatchData = (matchData: Match): Match => {
    return {
      id: matchData._id,
      ...matchData,
      competition:
        matchData.competition &&
        matchData.competition._id &&
        matchData.competition._id.toString(),
      venue:
        matchData.venue &&
        matchData.venue._id &&
        matchData.venue._id.toString(),
      homeTeam:
        matchData.homeTeam &&
        matchData.homeTeam._id &&
        matchData.homeTeam._id.toString(),
      awayTeam:
        matchData.awayTeam &&
        matchData.awayTeam._id &&
        matchData.awayTeam._id.toString(),
      score: {
        homeTeam: matchData.score.homeTeam
          ? matchData.score.homeTeam.toString()
          : '0',
        awayTeam: matchData.score.awayTeam
          ? matchData.score.awayTeam.toString()
          : '0',
      },
      offset: {
        homeTeam: matchData.offset ? matchData.offset.homeTeam : 0,
        awayTeam: matchData.offset ? matchData.offset.awayTeam : 0,
      },
      referee: matchData.referee && matchData.referee._id.toString(),
      round: matchData.round
        ? matchData.round
        : { category: 'Regular season', number: '' },
    }
  }

  const handleRemoveScore = async (team: string, id: string) => {
    let formData = parseExistingMatchData(matchData)

    if (team === 'home') {
      formData = {
        ...formData,
        tryScorers: {
          ...formData.tryScorers,
          homeTeam: matchData.tryScorers.homeTeam.filter(
            (x: any) => x._id !== id,
          ),
        },
      }
    } else if (team === 'away') {
      formData = {
        ...formData,
        tryScorers: {
          ...formData.tryScorers,
          awayTeam: formData.tryScorers.awayTeam.filter(
            (x: any) => x._id !== id,
          ),
        },
      }
    }

    if (updateLoading) return

    await updateMatch(formData).unwrap()
    toast.success('Matches updated succesfully')
  }

  const handleSubmit = async () => {
    let formData = parseExistingMatchData(matchData)

    if (teamType === 'homeTeam') {
      formData = {
        ...formData,
        tryScorers: {
          ...formData.tryScorers,
          homeTeam: [
            ...formData.tryScorers.homeTeam,
            {
              participantId: selectedPlayer?._id,
              event: score,
            },
          ],
        },
      }
    }

    if (teamType === 'awayTeam') {
      formData = {
        ...formData,
        tryScorers: {
          ...formData.tryScorers,
          awayTeam: [
            ...formData.tryScorers.awayTeam,
            {
              participantId: selectedPlayer?._id,
              event: score,
            },
          ],
        },
      }
    }

    if (updateLoading) return

    await updateMatch(formData).unwrap()
    toast.success('Matches updated succesfully')
  }

  const showScore = (val: string) => {
    if (val === 'try') {
      return ''
    }
    if (val === '2-point try') return '(2-point try)'
  }

  return (
    <Box component="form">
      <StyledGridContainer container justifyContent="space-between">
        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Team*
              </InputLabel>

              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={handleTeamChange}
                disableUnderline
              >
                <MenuItem value="">
                  <em>Select Team</em>
                </MenuItem>
                {matchData.homeTeam && (
                  <MenuItem value={matchData.homeTeam._id}>
                    <em>{matchData.homeTeam?.name}</em>
                  </MenuItem>
                )}
                {matchData.awayTeam && (
                  <MenuItem value={matchData.awayTeam._id}>
                    <em>{matchData.awayTeam?.name}</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Players*
              </InputLabel>

              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={handlePlayerChange}
                disableUnderline
              >
                {players &&
                  players.map(player => (
                    <MenuItem value={player._id}>
                      <em>
                        {player.firstName} {player.lastName}
                      </em>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Scoring Event*
              </InputLabel>

              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                onChange={handleScoreChange}
                disableUnderline
              >
                <MenuItem value="try">
                  <em>try</em>
                </MenuItem>
                <MenuItem value="2-point try">
                  <em>2-point try</em>
                </MenuItem>
              </Select>
            </FormControl>
          </TripleFormContent>
        </SecondGrid>
      </StyledGridContainer>
      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ marginTop: '-2rem' }}
          >
            ADD
          </StyledButton>
        </div>
      </StyledButtonDiv>

      <Grid container sx={{ marginTop: '1rem' }}>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Home
          </Typography>
          <Typography sx={{ marginBottom: '1rem' }}>
            {matchData.homeTeam?.name} <b>({homeTryScore})</b>
          </Typography>
          {homeTryScorers.length &&
            homeTryScorers.map(scorer => (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ maxWidth: '230px' }}
                >
                  <Typography sx={{ fontSize: '0.95rem' }}>
                    {scorer.participantId.firstName}{' '}
                    {scorer.participantId.lastName} {showScore(scorer.event)}
                  </Typography>
                  <Box>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => handleRemoveScore('home', scorer._id)}
                    >
                      <DeleteIcon sx={{ color: 'red' }} />
                    </IconButton>
                  </Box>
                </Box>
              </>
            ))}
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography sx={{ fontWeight: 'bold', color: '#008174' }}>
            Away
          </Typography>
          <Typography sx={{ marginBottom: '1rem' }}>
            {matchData.awayTeam?.name} <b>({awayTryScore})</b>
          </Typography>
          {awayTryScorers.length &&
            awayTryScorers.map(scorer => (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ maxWidth: '230px' }}
                >
                  <Typography sx={{ fontSize: '0.95rem' }}>
                    {scorer.participantId.firstName}{' '}
                    {scorer.participantId.lastName}
                    {showScore(scorer.event)}
                  </Typography>
                  <Box>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => handleRemoveScore('away', scorer._id)}
                    >
                      <DeleteIcon sx={{ color: 'red' }} />
                    </IconButton>
                  </Box>
                </Box>
              </>
            ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default TryScorerForm
