import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import MatchEditForm from 'manage-tritag/components/pages/matches/edit-form'

const MatchPage = (props: PageProps) => {
  return (
    <Layout label1="Matches" label2="Update" url="/matches">
      <MatchEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(MatchPage)
