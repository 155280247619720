import { FC } from 'react'
import {
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  styled,
  Box,
} from '@mui/material'
import { Team } from 'manage-tritag/services/api/endpoints/teams'

interface DeleteAssociationModalProps {
  setModal: (value: boolean) => void
  setTeamToForfeit: (val: any) => void
  homeTeam: Team | undefined
  awayTeam: Team | undefined
}

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalBody = styled('div')`
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
  max-width: 550px;
`

const StyledButton = styled(Button)`
  font-size: 0.8rem;
`

const ForfeitModal: FC<DeleteAssociationModalProps> = ({
  setModal,
  setTeamToForfeit,
  homeTeam,
  awayTeam,
}) => {
  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={() => setModal(false)}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalBody>
          <p id="transition-modal-title" className="montserrat bold">
            Forfeit Match
          </p>

          <Box>
            <FormControl component="fieldset">
              <FormLabel component="legend"> Select Team to Forfeit</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="radio-buttons-group"
                onChange={e => setTeamToForfeit(e.target.value)}
              >
                <FormControlLabel
                  value={homeTeam?._id}
                  control={<Radio />}
                  label={homeTeam?.name}
                />
                <FormControlLabel
                  value={awayTeam?._id}
                  control={<Radio />}
                  label={awayTeam?.name}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <div style={{ float: 'right', marginTop: '2.5rem' }}>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={() => setModal(false)}
              style={{
                marginRight: '1rem',
                background: 'white',
                color: '#000',
                border: '1px solid #008174',
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={() => setModal(false)}
              style={{
                padding: '0.4rem 1.5rem 0.4rem 1.5rem',
              }}
            >
              Continue
            </StyledButton>
          </div>
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

export default ForfeitModal
