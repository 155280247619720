import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { navigate, PageProps } from 'gatsby'
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tab,
  Tabs,
} from '@mui/material'
import TabPanel from 'manage-tritag/components/tab-panel'
import { useGetVenuesQuery } from 'manage-tritag/services/api/endpoints/venues'
import {
  Team,
  useGetRefereesQuery,
} from 'manage-tritag/services/api/endpoints/teams'
import { useGetCompetitionsQuery } from 'manage-tritag/services/api/endpoints/competitions'
import TryScorerForm from 'manage-tritag/components/tri-scorer/form'
import { noLimitParams } from 'manage-tritag/utils/string'
import {
  Match,
  useUpdateMatchMutation,
  useDeleteMatchMutation,
  useGetMatchQuery,
} from 'manage-tritag/services/api/endpoints/matches'
import DeleteModal from 'manage-tritag/components/modal/delete_modal'
import Spinner from 'manage-tritag/components/loading/spinner'
import ForfeitModal from 'manage-tritag/components/modal/forfeit_modal'
import { Participant } from 'manage-tritag/services/api/endpoints/participants'
import { useMediaQuery } from 'react-responsive'
import { DateTimePicker } from '@mui/x-date-pickers'
import {
  FormContent,
  SecondFormContent,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
} from './styles'

const MatchesEditForm = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 898px)` })
  const { params } = props

  const { data: matchData, isLoading: matchesLoading } = useGetMatchQuery(
    params?.id,
  )
  const { data: competitions, isLoading: competitionLoading } =
    useGetCompetitionsQuery({ ...noLimitParams, currentSeason: true })
  const { data: venues, isLoading: venueLoading } =
    useGetVenuesQuery(noLimitParams)
  const [updateMatch, { isLoading: updateLoading }] = useUpdateMatchMutation()
  const [deleteMatch] = useDeleteMatchMutation()
  const [modal, setModal] = useState(false)
  const [forfeitModal, setForfeitModal] = useState(false)
  const [teamToForfeit, setTeamToForfeit] = useState<string | undefined>(
    undefined,
  )
  const { handleSubmit, control, reset, watch, getValues } = useForm({
    defaultValues: {
      competition: '',
      round: {
        category: '',
        number: '',
      },
      matchDisplayName: '',
      venue: '',
      fieldNo: '',
      time: '',
      homeTeam: '',
      awayTeam: '',
      referee: '',
      score: {
        homeTeam: '',
        awayTeam: '',
      },
      offset: {
        homeTeam: '',
        awayTeam: '',
      },
      tryScorers: '',
      status: '',
      bye: false,
      tbc: false,
    },
  })

  const watchAllFields = watch()
  const [value, setValue] = useState(0)
  const [numOfRounds, setNumOfRounds] = useState<number | undefined>(0)
  const { data: referees } = useGetRefereesQuery({ currentSeason: true })
  const [teams, setTeams] = useState<Team[] | undefined>(undefined)

  useEffect(() => {
    if (watchAllFields.competition && competitions) {
      const selectedCompetition = competitions.find(
        x => x._id === watchAllFields.competition,
      )

      setNumOfRounds(selectedCompetition?.numberOfRounds)

      if (selectedCompetition?.teams.length) {
        setTeams(selectedCompetition?.teams)
      } else {
        setTeams(undefined)
      }
      if (
        parseInt(watchAllFields.round.number) >
        selectedCompetition?.numberOfRounds!
      ) {
        reset({
          ...watchAllFields,
          round: { ...watchAllFields.round, number: '' },
        })
      }
    }
  }, [watchAllFields.competition, competitions, watchAllFields, reset])

  useEffect(() => {
    if (matchData) {
      reset({
        ...matchData,
        competition:
          matchData.competition &&
          matchData.competition._id &&
          matchData.competition._id.toString(),
        venue:
          matchData.venue &&
          matchData.venue._id &&
          matchData.venue._id.toString(),
        homeTeam:
          matchData.homeTeam &&
          matchData.homeTeam._id &&
          matchData.homeTeam._id.toString(),
        awayTeam:
          matchData.awayTeam &&
          matchData.awayTeam._id &&
          matchData.awayTeam._id.toString(),
        score: {
          homeTeam: matchData.score.homeTeam
            ? matchData.score.homeTeam.toString()
            : '0',
          awayTeam: matchData.score.awayTeam
            ? matchData.score.awayTeam.toString()
            : '0',
        },
        offset: {
          homeTeam: matchData.offset
            ? matchData.offset.homeTeam.toString()
            : '0',
          awayTeam: matchData.offset
            ? matchData.offset.awayTeam.toString()
            : '0',
        },
        referee: matchData.referee && matchData.referee._id.toString(),
        tryScorers: matchData.tryScorers
          ? matchData.tryScorers
          : { awayTeam: [], homeTeam: [] },
        round: matchData.round
          ? matchData.round
          : { category: 'Regular season', number: '' },
      })
    }
  }, [matchData, reset])

  const renderRoundNumber = () => {
    const jsx = []
    if (numOfRounds) {
      for (let i = 0; i < numOfRounds; i++) {
        jsx.push(
          <MenuItem value={i + 1}>
            <em>{i + 1}</em>
          </MenuItem>,
        )
      }
      return jsx
    }
  }

  const handleDelete = async () => {
    setModal(false)
    await deleteMatch(params?.id).unwrap()
    toast.success('Matches deleted successfully.')
    navigate('/matches')
  }

  const handleCheckBox = async (type: string) => {
    if (type === 'bye') {
      reset({
        ...watchAllFields,
        tbc: false,
        awayTeam: '',
        bye: !watchAllFields.bye,
      })
    } else {
      reset({ ...watchAllFields, bye: false, tbc: !watchAllFields.tbc })
    }
  }

  const requiredVenue = () => {
    if (watchAllFields.bye || watchAllFields.tbc) {
      return false
    }
    return true
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const getTeam = (id: string) => {
    const team = teams?.filter(x => x._id === id)

    if (team) {
      return team[0]
    }
  }

  const onSubmit: SubmitHandler<Match> = async (updateData: any) => {
    if (getValues('status').toString() === '4' && !teamToForfeit) {
      setForfeitModal(true)
      if (!teamToForfeit) return
    }
    if (updateLoading) return

    const formData = {
      id: params.id,
      ...updateData,
      time: new Date(updateData.time),
      teamToForfeit,
    }
    await updateMatch(formData).unwrap()
    reset()
    toast.success('Matches updated succesfully.')
    navigate('/matches')
  }

  if (matchesLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(100vh - 145px);',
        }}
      >
        <Spinner size={40} color="#008174" />
      </Box>
    )

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginBottom: '1rem',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label="Match details"
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          />
          <Tab
            label="Try scorers"
            sx={{ fontWeight: 'bold', textTransform: 'none' }}
          />
        </Tabs>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <TabPanel value={value} index={0}>
          <StyledGridContainer container justifyContent="space-between">
            <Grid item xs={12} sm={12} md={12}>
              <FormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Competition *
                  </InputLabel>
                  <Controller
                    name="competition"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 1 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {!competitionLoading &&
                          competitions &&
                          competitions.map(competition => (
                            <MenuItem
                              key={competition._id}
                              value={competition._id}
                            >
                              {competition.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Round type *
                  </InputLabel>
                  <Controller
                    name="round.category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 2 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                        onChange={(e: any) => {
                          field.onChange(e)
                          if (
                            e.target.value.toLowerCase() === 'regular season'
                          ) {
                            reset({ ...watchAllFields, matchDisplayName: '' })
                          }
                        }}
                      >
                        <MenuItem value="Regular season">
                          Regular season
                        </MenuItem>
                        <MenuItem value="Finals">Finals</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Round number *
                  </InputLabel>
                  <Controller
                    name="round.number"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 3 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                        disabled={!numOfRounds}
                      >
                        {renderRoundNumber()}
                      </Select>
                    )}
                  />
                </FormControl>
              </FormContent>

              <SecondFormContent>
                <Controller
                  name="matchDisplayName"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      inputProps={{ tabIndex: 4 }}
                      label="Match display name"
                      type="text"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      {...field}
                      disabled={
                        watchAllFields.round.category.toLowerCase() ===
                        'regular season'
                      }
                      required
                    />
                  )}
                />

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Venue {requiredVenue() ? '*' : ''}
                  </InputLabel>
                  <Controller
                    name="venue"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 5 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required={requiredVenue()}
                      >
                        {requiredVenue() && (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}

                        {!venueLoading &&
                          venues &&
                          venues.map(venue => (
                            <MenuItem value={venue._id}>{venue.name}</MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="fieldNo"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 6 }}
                      id="filled-basic"
                      label="Field number"
                      variant="filled"
                      size="small"
                      {...field}
                    />
                  )}
                />
              </SecondFormContent>

              <SecondFormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Home Team
                  </InputLabel>
                  <Controller
                    name="homeTeam"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required={watchAllFields.bye}
                      >
                        {!watchAllFields.bye && (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}

                        {teams &&
                          teams.length &&
                          teams.map((team: Team) => (
                            <MenuItem key={team._id} value={team._id}>
                              {team.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Away Team
                  </InputLabel>
                  <Controller
                    name="awayTeam"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 8 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        disabled={watchAllFields.bye}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {teams &&
                          teams.length &&
                          teams.map((team: Team) => (
                            <MenuItem key={team._id} value={team._id}>
                              {team.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="time"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      label="Date / Time"
                      inputFormat="DD/MM/YYYY h:mm A"
                      InputProps={{ disableUnderline: true, error: false }}
                      renderInput={(renderParams: any) => (
                        <StyledTextField
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ tabIndex: 9 }}
                          id="filled-basic"
                          variant="filled"
                          size="small"
                          {...renderParams}
                          required
                        />
                      )}
                    />
                  )}
                />
              </SecondFormContent>

              <SecondFormContent>
                <Controller
                  name="score.homeTeam"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Home Team score"
                      type="text"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 10 }}
                      {...field}
                      disabled
                    />
                  )}
                />

                <Controller
                  name="score.awayTeam"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Away Team score"
                      type="text"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 11 }}
                      {...field}
                      disabled
                    />
                  )}
                />

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Status *
                  </InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 12 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        <MenuItem value={1}>
                          <em>Fixture</em>
                        </MenuItem>
                        <MenuItem value={2}>
                          <em>Postponed</em>
                        </MenuItem>
                        <MenuItem value={3}>
                          <em>Abandoned</em>
                        </MenuItem>
                        <MenuItem value={4}>
                          <em>Forfeit</em>
                        </MenuItem>
                        <MenuItem value={5}>
                          <em>Final</em>
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </SecondFormContent>

              <SecondFormContent>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Referee
                  </InputLabel>
                  <Controller
                    name="referee"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                      >
                        {(referees ?? [])
                          .filter((referee: Participant) => referee?._id)
                          .map((referee: Participant) => (
                            <MenuItem key={referee._id} value={referee._id}>
                              {referee.firstName} {referee.lastName}
                            </MenuItem>
                          ))}
                        <MenuItem key="unknown" value="">
                          Unknown
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>

                <Controller
                  name="bye"
                  control={control}
                  render={({ field }) => (
                    <FormGroup style={{ padding: '0.2rem', width: '100%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={watchAllFields.bye}
                            inputProps={{ tabIndex: 14 }}
                          />
                        }
                        label="Bye"
                        {...field}
                        onChange={(e: any) => {
                          field.onChange(e)
                          handleCheckBox('bye')
                        }}
                      />
                    </FormGroup>
                  )}
                />

                <Controller
                  name="tbc"
                  control={control}
                  render={({ field }) => (
                    <FormGroup style={{ padding: '0.2rem', width: '100%' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={watchAllFields.tbc}
                            inputProps={{ tabIndex: 15 }}
                          />
                        }
                        label="Match details TBC"
                        {...field}
                        onChange={(e: any) => {
                          field.onChange(e)
                          handleCheckBox('tbc')
                        }}
                      />
                    </FormGroup>
                  )}
                />
              </SecondFormContent>

              <SecondFormContent>
                <Controller
                  name="offset.homeTeam"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Home Team offset score"
                      type="number"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 10 }}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="offset.awayTeam"
                  control={control}
                  render={({ field }) => (
                    <StyledTextField
                      label="Away Team offset score"
                      type="number"
                      size="small"
                      variant="filled"
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ tabIndex: 11 }}
                      {...field}
                    />
                  )}
                />

                {!isMobile && <Box sx={{ width: '100%' }} />}
              </SecondFormContent>
            </Grid>
          </StyledGridContainer>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {matchData && <TryScorerForm matchData={matchData} />}
        </TabPanel>

        <StyledButtonDiv>
          <StyledButton
            variant="contained"
            onClick={() => {
              setModal(true)
            }}
            style={{
              backgroundColor: 'red',
              color: '#fff',
            }}
          >
            DELETE
          </StyledButton>

          <div>
            <StyledButton
              variant="outlined"
              onClick={() => {
                navigate('/matches')
              }}
              style={{
                border: '2px solid #008174',
                color: '#000',
                marginRight: '1.5rem',
              }}
            >
              CANCEL
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              type="submit"
              style={{ width: '83px' }}
            >
              {updateLoading ? <Spinner size={22} /> : 'SAVE'}
            </StyledButton>
          </div>
        </StyledButtonDiv>
      </Box>
      {modal && (
        <DeleteModal
          setModal={setModal}
          label="matches"
          handleDelete={handleDelete}
        />
      )}
      {forfeitModal && (
        <ForfeitModal
          setModal={setForfeitModal}
          setTeamToForfeit={setTeamToForfeit}
          awayTeam={getTeam(getValues('awayTeam'))}
          homeTeam={getTeam(getValues('homeTeam'))}
        />
      )}
    </Box>
  )
}

export default MatchesEditForm
